<template>
  <div>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div class="card-icon" style="background: #1565C0 !important;">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-4">
              Asignar bodegas a Wellboats
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <div class="toolbar">
          <!--        Here you can write extra buttons/actions for the toolbar              -->
        </div>
        <div class="material-datatables">
            <v-simple-table
                dense
                class="mx-2 my-2 elevation-3 text-center"
                no-data-text="No hay datos de costo"
                :search="search"
                :header-props="{ sortIcon: 'arrow_upward' }"
                fixed-header
            >
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center blue-grey darken-4 white-text">Wellboat</th>
                        <th class="text-center blue-grey darken-4 white-text" v-for="b in bodegas" v-bind:key="b.id">
                            {{ b.nombre }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(wellboat, w) in wellboats" :key="w.id">
                        <td class="text-left">{{ wellboat.nombre }}</td>
                        <td v-for="(bodega, b) in bodegas" v-bind:key="bodega.number">
                            <input type="checkbox" v-model="bodegasListBody[w]['b'+b]" @change="save(wellboat, bodega, bodegasListBody[w]['b'+b])"/>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
    import {mapState, mapMutations} from 'vuex'
    export default {
    data: () => ({
        color: 'green',
        snackbar: false,
        errors: [],
        validation_message: '',
        success: null,
        search: null,
        permisoIndex: -1,
        bodegas: [],
        wellboats: [],
        all: false,
        bodegasListHeaders: [
            {
                text: 'Wellboat',
                align: 'center',
                sortable: true,
                value: 'nombre',
                class: "blue-grey darken-4 white-text"
            },
        ],
        bodegasListBody: [],
    }),
    mounted() {
        this.loadBodegas();
        this.loadWellboats();
        this.loadWellboatBodegas();
        this.loadBodegasWellboat();
    },
    methods:{
        async loadBodegas(){
            let url = `${this.base_url}bodegas`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.bodegas = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadWellboats(){
            let url = `${this.base_url}wellboats/lista/select`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.wellboats = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadWellboatBodegas(){
            let url = `${this.base_url}wellboats/bodegas/all`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.bodegasListBody = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadBodegasWellboat(){
            let url = `${this.base_url}wellboat_bodegas/bywellboat/1`;
            await this.axios.get(url, this.headers).then((response)=>{
                console.log(response.data);
            }).catch((error)=>{
                console.log(error);
            });
        },
        async save(wellboat, bodega, valor){
            let url = `${this.base_url}wellboat_bodegas`;
            let data = {
                'wellboats_id': wellboat.id,
                'bodegas_id': bodega.id,
                'm3': valor > 0 ? valor: 0
            };
            await this.axios.post(url, data, this.headers).then((response)=>{
                console.log(response);
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
    }
  }
</script>